import { useAuth } from '@/app/providers/auth-provider';
import { useEffect, useMemo, useState } from 'react';

import { ModalPurchase } from '@/components/modal-purchase';
import { UserRoleType } from '@/types/types.generated';
import type { IconType } from 'react-icons';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaGraduationCap } from 'react-icons/fa6';
import { GoHomeFill } from 'react-icons/go';
import { MdEventNote, MdLibraryBooks, MdOutlineShoppingBag } from 'react-icons/md';
import { TbInfoCircle } from 'react-icons/tb';

export interface MentorNavLink {
  label: string;
  link: string;
  notificationType?: string;
  icon: IconType;
  type: undefined;
}

export interface StudentNavLink {
  label: string;
  link: string;
  type?: 'trial' | 'modal' | 'external';
  modal?: JSX.Element;
  icon: IconType;
  notificationType: undefined;
  newTab?: boolean;
}

export const useMenuList = () => {
  const { user } = useAuth();
  const [navLinks, setNavLinks] = useState<MentorNavLink[] | StudentNavLink[]>([]);

  const mentorNavLinks: MentorNavLink[] = useMemo(
    () => [
      {
        label: 'manage_appointments',
        link: '/mentor/manage-appointments',
        icon: GoHomeFill,
        type: undefined,
      },
      {
        label: 'lessons',
        link: '/mentor/lesson-calendar',
        notificationType: 'check_requests', //for notifications filter
        icon: FaRegCalendarAlt,
        type: undefined,
      },
      {
        label: 'my_availability',
        link: '/mentor/availability',
        icon: FaRegCalendarAlt,
        type: undefined,
      },
      {
        label: 'curriculum',
        link: 'https://naonow.learnworlds.com/home-mentors',
        icon: MdLibraryBooks,
        type: undefined,
      },
    ],
    [],
  );

  const studentNavLinks: StudentNavLink[] = useMemo(
    () => [
      {
        label: 'dashboard',
        link: '/student/manage-lessons',
        icon: GoHomeFill,
        notificationType: undefined,
      },
      {
        label: 'lessons',
        link: '/student/lesson-calendar',
        icon: MdEventNote,
        notificationType: undefined,
      },
      {
        label: 'mentors',
        link: '/student/mentors-list',
        icon: FaGraduationCap,
        type: 'trial',
        modal: <ModalPurchase />,
        notificationType: undefined,
      },
      {
        label: 'purchase',
        link: '/student/subscriptions',
        icon: MdOutlineShoppingBag,
        notificationType: undefined,
      },
      // {
      //   label: 'free_classes',
      //   link: '#',
      //   icon: IoGiftOutline,
      //   type: 'modal',
      //   modal: <Info />,
      //   notificationType: undefined,
      // },
      {
        label: 'class_material',
        link: user?.phoneNumber?.startsWith('+82')
          ? 'https://naonow.learnworlds.com/home-kr'
          : user?.phoneNumber?.startsWith('+886')
            ? 'https://naonow.learnworlds.com/home-tw'
            : 'https://naonow.learnworlds.com/home',
        icon: MdLibraryBooks,
        type: 'external',
        notificationType: undefined,
      },
      {
        label: 'guidebook',
        link: 'https://doc.clickup.com/9014152611/d/h/8cmj3d3-11194/bf6b07b2401e7a4',
        icon: TbInfoCircle,
        type: 'external',
        newTab: true,
        notificationType: undefined,
      },
    ],
    [user],
  );

  useEffect(() => {
    if (user?.role === UserRoleType.Mentor) {
      setNavLinks(mentorNavLinks);
    } else if (user?.role === UserRoleType.Student) {
      setNavLinks(studentNavLinks);
    }
  }, [user]);

  return navLinks;
};
